import React, { useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Formik, Form } from 'formik'
import {
  Title,
  Container,
  Button,
  Grid,
  GridCol,
  LoaderWithParagraph,
} from '@kisskissbankbank/kitten'
import { Text } from '../../components/form'
import { gql, useMutation } from '@apollo/client'

const SIGN_IN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!, $gaCode: Int) {
    signIn(email: $email, password: $password, gaCode: $gaCode) {
      user {
        id
        email
      }
    }
  }
`

const Login = () => {
  const router = useRouter()
  const [loading, isLoading] = useState(false)
  const [login] = useMutation(SIGN_IN_MUTATION)
  if (loading) {
    return (
      <Container>
        <Head>
          <title>Page de connexion</title>
        </Head>
        <LoaderWithParagraph
          loaderPosition="top"
          className="k-u-margin-top-octuple"
        >
          Connection en cours…
        </LoaderWithParagraph>
      </Container>
    )
  }
  return (
    <>
      <Head>
        <title>Page de connexion</title>
      </Head>
      <Container>
        <Title className="k-u-align-center k-u-margin-top-octuple">
          Connexion
        </Title>
        <Grid>
          <GridCol col-l="4" offset-l="4">
            <Formik
              initialValues={{ email: '', password: '', gaCode: '' }}
              onSubmit={async (values) => {
                const { email, password, gaCode } = values
                try {
                  isLoading(true)
                  const response = await login({
                    variables: {
                      email,
                      password,
                      ...(gaCode && { gaCode }),
                    },
                  })
                  console.log('[LOGIN PAGE] login response', response)
                  console.log(
                    '[LOGIN PAGE] routing push',
                    router?.query?.redirectUrl || '',
                  )
                  await router.push(router?.query?.redirectUrl || '')
                } catch (e) {
                  console.error(e)
                } finally {
                  isLoading(false)
                }
              }}
            >
              {() => {
                return (
                  <Form className="k-u-margin-top-quadruple">
                    <Text name="email" type="email" placeholder="Email" />
                    <Text
                      name="password"
                      type="password"
                      placeholder="Mot de passe"
                    />
                    <Text
                      name="gaCode"
                      pattern="[0-9]{6}"
                      autocomplete="one-time-code"
                      inputmode="numeric"
                      type="number"
                      placeholder="Code Google Authenticator"
                    />

                    <Button
                      modifier="helium"
                      className="k-u-margin-top-double"
                      variant="orion"
                      type="submit"
                      fluid
                    >
                      Se connecter
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </GridCol>
        </Grid>
      </Container>
    </>
  )
}

export default Login
